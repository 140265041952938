import styled from '@emotion/styled'
import Modal from 'react-modal'

export const ModalWrapper = styled(Modal)`
  font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  background: ${props => props.theme.colors.body.background};
  height: 100vh;
  width: 100%;
  display: block;
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    padding-top: 2rem;
  }

`
export const ImagesContainer = styled.div`
  max-width: 100vw;
  text-align: center;
  overflow: hidden;

  > div > div > div > div > div > div > div {
    max-height: 80vh!important;
    ${'' /* max-width: 80vw!important; */}
    @media (max-width: ${props => props.theme.breakpoints.m}) {
      width: 600px!important;
    }
    @media (max-width: ${props => props.theme.breakpoints.xs}) {
      max-height: 70vh!important;
      width: 320px!important;
    }
    img {
      object-fit: contain!important;
      background-color: rgba(242, 242, 242, 1);
    }
  }
  

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    margin-top: 10px;
  }
  > div {
    > ul {
      bottom: -40px;
      @media (max-width: ${props => props.theme.breakpoints.m}) {
        display: none!important;
      }
    }
  }
`
export const CloseModal = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  margin: 2rem;
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none!important;
  z-index: 3;
  img {
    max-width: 12px;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin: .8rem .1rem .1rem .1rem;
    img {
      max-width: 10px;
    }
  }
`