import React from 'react'
import Slider from 'react-slick'

import PhotoPresentation from '../PhotoPresentation';
import { CustomPrevArrow, CustomNextArrow } from './CustomArrows'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class extends React.Component {

  state = {
    activeSlide: 0,
    slides: [],
    loadedSlides: [],
    loaded: false,
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener("resize", this.computeLegendWidth);
      window.addEventListener('keydown', this.keyPressHandler);
      window.zoomInstances = [];
    }

    const slides = this.props.images.length > 0 ? this.props.images.map((image, index) => {
      const {
        data: {
          description,
          legend,
          photo: {
            localFile: {
              childImageSharp: {
                fluid: {
                  srcWebp
                }
              }
            }
          }
        }
      } = image;

      const data = {
        index: index,
        uid: image.uid,
        src: srcWebp,
        xoriginal: image.data.photo.localFile.url,
        legend: legend.text,
        description: description.html
      };
      
      return data
    }) : [];

    this.setState({ 
      slides: slides,
      activeSlide: this.props.currentSlide,
      loaded: true
    })
  }
  
  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('keydown', this.keyPressHandler);
      window.removeEventListener('resize', this.computeLegendWidth);
    }
  }

  keyPressHandler = (e) => {
    if (e.keyCode === 39) {
      this.next();
    } else if(e.keyCode === 37) {
      this.previous();
    }
  }

  computeLegendWidth = () => {
    if (typeof document !== 'undefined') {
      const image = document.querySelector('.slick-active > div > div > img');
      const description = document.querySelector('.slick-active > div > div > div');

      if (image && description) {
        description.style.width = `${image.offsetWidth}px`;
      }
    }
  }

  next = () => {
    this.slider.slickNext();
    if (typeof window !== 'undefined') {
      window.zoomInstances.forEach(item => item.closezoom())
    }
  }

  previous = () => {
    this.slider.slickPrev();
    if (typeof window !== 'undefined') {
      window.zoomInstances.forEach(item => item.closezoom())
    }
  }
  
  render() {
    
    const { activeSlide, loaded, slides } = this.state;
    const settings = {
      prevArrow: <CustomPrevArrow />,
      nextArrow: <CustomNextArrow />,
      adaptiveHeight: true,
      focusOnSelect: true,
      accessibility: true,
      focusOnChange: true,
      infinite: true,
      speed: 0,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: this.props.currentSlide || 0,
      dots: false,
      fade: false,
      afterChange: current => this.setState({ activeSlide: current }),
    };
    
    return (
      <React.Fragment>
        {
          (slides && loaded) && (
            <Slider ref={c => (this.slider = c)} {...settings}>
              {slides.length > 0 && slides.map((image, index) => {
                return (
                  <React.Fragment key={`image-${index}`}>
                    {(activeSlide === index) && (
                      <React.Fragment>
                        <PhotoPresentation
                          activeSlide={activeSlide}
                          index={index}
                          uid={image.uid}
                          src={image.src}
                          xoriginal={image.xoriginal}
                          legend={image.legend}
                          description={image.description}
                          computeLegendWidth={this.computeLegendWidth}
                        />
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              )}
            </Slider>
          )
        }
      </React.Fragment>
    )
  }
}