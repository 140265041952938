import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { PhotoContainer } from "../containers/PhotoContainer";

const Photo = ({
  item,
  handleModalBanner,
  modalBanner,
  index,
  position
}) => {
  const { data: { photo, legend } } = item;
  
  return (
    <PhotoContainer
      className={typeof position !== 'undefined' ? 'gallery' : 'default'}
      style={typeof position !== 'undefined' ? position : {}}
    >
      <GatsbyImage
        image={getImage(photo.localFile)}
        alt={legend.text}
        onClick={() => handleModalBanner({
          modalBanner: !modalBanner,
          initialSlide: index
        })}
      />
    </PhotoContainer>
  )
}

export default Photo