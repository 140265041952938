exports.computePhotosCollection = (nodes, activeFilters) => {
  let photos = nodes.map((item, index) => {
    const newItem = {
      index: index,
      uid: item.uid,
      src: item.data.photo.localFile.childImageSharp.fluid.srcWebp,
      width: item.data.photo.localFile.childImageSharp.fluid.presentationWidth,
      height: item.data.photo.localFile.childImageSharp.fluid.presentationHeight,
      sizes: item.data.photo.localFile.childImageSharp.fluid.sizes,
      srcSet: item.data.photo.localFile.childImageSharp.fluid.srcSetWebp,
      alt: item.data.description.text,
      title: item.data.legend.text,
      data: {
        ...item.data,
        filters: item.data.body.filter(i => i.__typename === "PrismicPhotoBodyCategoriesFiltres").map((i) => {
          return i.items.map((_i) => {
            return _i.filter.slug
          })
        })[0],
        formats: item.data.body.filter(i => i.__typename === "PrismicPhotoBodyFormat").map((i) => {
          return i.items.map((_i) => {
            return _i.format.document.data.format
          })
        })[0]
      }
    }
    return newItem;
  });

  if (activeFilters.length > 0) {
    return photos.filter((item) => typeof item.data.filters !== 'undefined')
      .filter((item) => {
        return activeFilters.filter(i => item.data.filters.indexOf(i) !== -1).length > 0
      })
  }

  return photos
}