import React from 'react'
import { connect } from 'react-redux'
import Slider from '../Slick/Slider'

import { handleModalBanner } from '../../actions/modal'

import Prev from '../../../static/prev.svg'
import Next from '../../../static/next.svg'
import "../../styles/modal.css";

import { 
  ModalWrapper, 
  ImagesContainer, 
  CloseModal,
} from '../../containers/ModalContainer'

class ModalPhoto extends React.Component {

  removeZoomImages = () => {
    if (typeof window !== 'undefined') {
      window.zoomInstances.forEach(item => item.closezoom())
    }
  }

  render() {

    const { images, initialSlide, modalBanner, handleModalBanner } = this.props

    const modalStyles = { overlay: {} };
    modalStyles.overlay["zIndex"] = 2;
    
    return (
      <ModalWrapper
        isOpen={modalBanner}
        backdropColor={'white'}
        backdropOpacity={1}
        closeTimeoutMS={200}
        ariaHideApp={false}
        onRequestClose={() => {
          this.removeZoomImages()
          handleModalBanner(false)
        }}
        shouldCloseOnOverlayClick={true}
        style={modalStyles}
      >
        <div className="modal-container">
          <CloseModal onClick={() => {
            this.removeZoomImages()
            handleModalBanner(false)
          }}>
            <img src={Next} alt="suivant" />
            <img src={Prev} alt="précédent" />
          </CloseModal>

          <ImagesContainer>
            <Slider 
              modal={modalBanner} 
              images={images} 
              currentSlide={initialSlide}
            />
          </ImagesContainer>
        </div>
      </ModalWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.modalBanner
});
const mapDispatchToProps = (dispatch) => ({
  handleModalBanner: (state) => dispatch(handleModalBanner(state))
});
export default connect(mapStateToProps, mapDispatchToProps)(ModalPhoto);