import styled from '@emotion/styled'

export const PhotoContainer = styled.div`
  cursor: pointer;
  transition: all .4s linear;
  
  img {
    max-width: 100%;
  }
  
  &:hover {
    opacity: .8
  }

  &.gallery {
    position: absolute;
  }
`;

export const InfosContainer = styled.div`
  
  text-align: right;
  h3 {
    font-size: 1.6rem;
    margin: .5rem 0;
    color: #999;
  }

  p {
    font-size: 1.2rem;
    text-transform: uppercase;
    margin: 0;
    line-height: 2rem;
    color: #999;
  }
`;