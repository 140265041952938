import * as React from "react"
import jQuery from 'jquery';
import { InfosContainer } from "../containers/PhotoContainer"

class PhotoPresentation extends React.Component {

  state = {
    loaded: false,
    image: null,
    src: null,
  }

  componentDidMount() {
    const { uid, src } = this.props;
    const image = new Image();

    if (window !== 'undefined' && document !== 'undefined') {
      image.onload = () => {
        window.zoomInstances.forEach(item => item.closezoom())
        this.setState({ 
          src: src, 
          loaded: true,
          image: image
        });

        jQuery(document).ready(function () {
          jQuery(`#${uid} img.xzoom`).xzoom({
            lensReverse: false,
            smoothScale: 10,
            position: 'inside',
          });

          var instance = jQuery(`#${uid} img.xzoom`).data('xzoom');
          window.zoomInstances = [...window.zoomInstances, instance];

          if (typeof instance !== 'undefined') {

            instance.eventunbind();
            jQuery(`#${uid} img.xzoom`).click(function (event) {

              instance.eventleave = function (element) {
                element.on('click', function (event) {
                  instance.closezoom();
                });
              }

              instance.eventscroll = function (element) {
                element.on('mousewheel DOMMouseScroll', function (event) {
                  instance.xscroll(event);
                })
              }

              instance.eventmove = function (element) {
                element.on('mousemove', function (event) {
                  instance.movezoom(event);
                })
              }

              instance.openzoom(event);
            })
          }
        })
      }
      image.src = src;
    }
    
  }

  componentDidUpdate() {
    if (this.state.loaded) {
      if (typeof document !== 'undefined') {
        const image = document.querySelector('.slick-active > div > div > img');
        const description = document.querySelector('.slick-active > div > div > div');
        if (image && description) {
          
          setTimeout(() => {
            description.style.width = `${image.offsetWidth}px`;
            
            image.classList.add("active");
            description.classList.add("active");
          }, 500);
        }
      }
    }
  }

  render() {

    const { 
      index,
      uid,
      xoriginal,
      legend,
      description
     } = this.props

    const { src, loaded } = this.state;
    
    return (
      <div id={`${uid}`}>
        {
          loaded && (
            <React.Fragment>
              <img
                style={{ cursor: 'pointer' }}
                alt={`marlo-disch-${index}`}
                className="xzoom"
                src={src}
                xoriginal={xoriginal}
              />
              <InfosContainer>
                <h3>{legend}</h3>
                <div
                  className="description"
                  dangerouslySetInnerHTML={{ __html: description }}
                ></div>
              </InfosContainer>
            </React.Fragment>
          )
        }
      </div>
    )
  }
}

export default PhotoPresentation